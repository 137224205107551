var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.showcoupon,
            title: _vm.title,
            center: "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showcoupon = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, "label-width": "130px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请输入考勤分组名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工作日：", prop: "work_day" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "220px" },
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.form.work_day,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "work_day", $$v)
                                },
                                expression: "form.work_day",
                              },
                            },
                            _vm._l(_vm.selectTime, function (list) {
                              return _c("el-option", {
                                key: list.id,
                                attrs: { label: list.name, value: list.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工作时间：", prop: "Time" } },
                        [
                          _c("el-time-picker", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              "is-range": "",
                              format: "HH:mm:ss",
                              "value-format": "HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围",
                            },
                            model: {
                              value: _vm.form.Time,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "Time", $$v)
                              },
                              expression: "form.Time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "拍照设置：", prop: "photo_set" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.photo_set,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "photo_set", $$v)
                                },
                                expression: "form.photo_set",
                              },
                            },
                            _vm._l(_vm.Setup, function (list) {
                              return _c("el-option", {
                                key: list.id,
                                attrs: { label: list.name, value: list.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "考勤地点：", prop: "address_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.address_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "address_id", $$v)
                                },
                                expression: "form.address_id",
                              },
                            },
                            _vm._l(_vm.address, function (list) {
                              return _c("el-option", {
                                key: list.id,
                                attrs: { label: list.name, value: list.id },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handlerattlist },
                            },
                            [_vm._v(" 考勤列表 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效半径：", prop: "scope" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: "请输入有效半径",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.scope,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "scope", $$v)
                              },
                              expression: "form.scope",
                            },
                          }),
                          _c("span", [_vm._v("米")]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：", prop: "status" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("正常"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("停用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("add-attendance", {
                ref: "att",
                on: { getlist: _vm.getlist },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showcoupon = !_vm.showcoupon
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }