<!--
 * @Author: your name
 * @Date: 2021-01-27 15:53:46
 * @LastEditTime: 2021-03-27 11:31:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\attendance\components\Addcoupon.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="showcoupon"
      :title="title"
      center
      width="700px"
    >
      <div>
        <el-row>
          <el-form ref="form" :model="form" label-width="130px">
            <el-form-item label="名称：" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入考勤分组名称"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="工作日：" prop="work_day">
              <el-select v-model="form.work_day" style="width: 220px" multiple>
                <el-option
                  v-for="list in selectTime"
                  :key="list.id"
                  :label="list.name"
                  :value="list.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作时间：" prop="Time">
              <el-time-picker
                v-model="form.Time"
                style="width: 220px"
                is-range
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
              ></el-time-picker>
            </el-form-item>
            <el-form-item label="拍照设置：" prop="photo_set">
              <el-select v-model="form.photo_set">
                <el-option
                  v-for="list in Setup"
                  :key="list.id"
                  :label="list.name"
                  :value="list.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考勤地点：" prop="address_id">
              <el-select v-model="form.address_id">
                <el-option
                  v-for="list in address"
                  :key="list.id"
                  :label="list.name"
                  :value="list.id"
                ></el-option>
              </el-select>
              <el-button type="primary" @click="handlerattlist">
                考勤列表
              </el-button>
            </el-form-item>
            <el-form-item label="有效半径：" prop="scope">
              <el-input
                v-model="form.scope"
                placeholder="请输入有效半径"
                onkeyup="value=value.replace(/[^\d.]/g,'') "
                style="width: 120px"
              />
              <span>米</span>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
              <el-radio-group v-model="form.status">
                <el-radio label="1">正常</el-radio>
                <el-radio label="2">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-row>
        <add-attendance ref="att" @getlist="getlist"></add-attendance>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>
        <el-button @click="showcoupon = !showcoupon">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import AddAttendance from './AddAttendance.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AddAttendance,
    },
    data() {
      return {
        showcoupon: false,
        title: '考勤分组',
        form: {
          // id: '', //id，修改时传id，添加时传0
          Time: [new Date(2016, 9, 10, 8, 30), new Date(2016, 9, 10, 18, 30)],
          name: '', //名称
          work_day: '', //工作日，格式1,2,3,4,5
          start_time: '08:30:00', //开始时间   08:30:00
          end_time: '18:30:00', //结束时间   18:30:00
          photo_set: '', //拍照设置0不强制拍照，1每次强制拍照
          address_id: '', //打开地址id
          scope: '', //有效半径
          status: '1', //状态1开启，2关闭
        },

        selectTime: [
          {
            id: '1',
            name: '周一',
          },
          {
            id: '2',
            name: '周二',
          },
          {
            id: '3',
            name: '周三',
          },
          {
            id: '4',
            name: '周四',
          },
          {
            id: '5',
            name: '周五',
          },
          {
            id: '6',
            name: '周六',
          },
          {
            id: '0',
            name: '周日',
          },
        ],
        Setup: [
          {
            id: '0',
            name: '不强制拍照',
          },
          {
            id: '1',
            name: '强制拍照',
          },
        ],
        url: {
          ress: '/setAdmin/attendance/address-list',
          save: '/setAdmin/attendance/save',
          info: '/setAdmin/attendance/info',
        },
        address: [],
      }
    },
    watch: {
      showcoupon(val) {
        if (val == false) {
          this.$refs['form'].resetFields()
          try {
            delete this.form.id
          } catch (error) {}
          console.log(this.form)
        }
      },
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } catch (error) {}
      },
    },
    mounted() {
      this.handlerRess()
    },
    methods: {
      getlist() {
        this.handlerRess()
      },
      handlerattlist() {
        this.$refs.att.baidulist = true
      },
      handlerData(row) {
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res)
            Object.assign(this.form, res.data)
            this.form.work_day = this.form.work_day.split(',')
            var a = new Array()
            a.push(res.data.start_time)
            a.push(res.data.end_time)
            this.form.Time = a
            console.log(this.form)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlersave() {
        var a = JSON.parse(JSON.stringify(this.form))
        delete a.Time
        a.work_day = a.work_day.join(',')
        postAction(this.url.save, a)
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.$emit('getlist')
              this.showcoupon = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerRess() {
        postAction(this.url.ress, {})
          .then((res) => {
            console.log(res)
            this.address = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style>
  .el-range-separator {
    display: none !important;
  }
</style>
