<!--
 * @Author: your name
 * @Date: 2021-01-26 15:10:20
 * @LastEditTime: 2021-03-25 10:14:57
 * @LastEditors: Please set LastEditors
 * @Description: 考勤设置
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\attendance\index.vue
-->
<template>
  <div style="padding: 20px">
    <el-row :gutter="20">
      <el-col :span="17">
        <el-button type="primary" @click="handlerInquire">
          查询考勤列表
        </el-button>
      </el-col>
      <el-col :span="3">
        <el-button @click="handlerAdd">添加考勤分组</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table stripe :data="tableData">
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '考勤员工'" #default="{ row }">
            <span class="font" @click="handleradduser(row)">
              {{ row.user_count }}
            </span>
          </template>
          <template v-else-if="list.label == '考勤时间'" #default="{ row }">
            <span>{{ row.start_time }}-{{ row.end_time }}</span>
          </template>
          <template v-else-if="list.label == '开关'" #default="{ row }">
            <el-switch
              v-model="row.status"
              :active-value="1"
              :inactive-value="2"
              @change="handlerSwitch(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="220px">
          <template #default="{ row }">
            <el-button type="text" @click="handlermodify(row)">设置</el-button>
            <el-popconfirm
              title="您真的要删除这个分组吗？"
              @confirm="handlerdelete(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10,20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row> -->
    <addcoupon ref="coupon" @getlist="handlergetlist"></addcoupon>
    <add-user ref="user" @getlist="handlergetlist"></add-user>
  </div>
</template>

<script>
  import Addcoupon from './components/Addcoupon.vue'
  import { postAction } from '@/api/Employee'
  import AddUser from './components/AddUser.vue'
  export default {
    components: {
      Addcoupon,
      AddUser,
    },
    data() {
      return {
        url: {
          list: '/setAdmin/attendance/list',
          switch: '/setAdmin/attendance/change-status',
          delete: '/setAdmin/attendance/delete',
        },
        tableData: [],
        total: 0,
        colemd: [
          {
            label: '考勤组名称',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '考勤员工',
            align: 'center',
            prop: 'user_count',
            width: '',
          },
          {
            label: '考勤时间',
            align: 'center',
            prop: 'start_time',
            width: '',
          },
          {
            label: '打卡地点',
            align: 'center',
            prop: 'address',
            width: '',
          },
          {
            label: '有效半径',
            align: 'center',
            prop: 'scope',
            width: '',
          },
          {
            label: '工作日',
            align: 'center',
            prop: 'work_day',
            width: '',
          },
          {
            label: '打卡方式',
            align: 'center',
            prop: 'photo_set_text',
            width: '',
          },
          {
            label: '开关',
            align: 'center',
            prop: 'status',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handleradduser(row) {
        this.$refs.user.showuser = true
        this.$refs.user.handlreinfo(row)
      },
      handlerdelete(row) {
        postAction(this.url.delete, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlermodify(row) {
        this.$refs.coupon.handlerData(row)
        this.$refs.coupon.showcoupon = true
      },
      handlergetlist() {
        this.handlerInquire()
      },
      handlerAdd() {
        this.$refs.coupon.showcoupon = true
      },
      handlerSwitch(row) {
        console.log(row)
        postAction(this.url.switch, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerInquire() {
        // this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`)
      //   this.form.pageSize = val
      //   this.handlerlist()
      // },
      // handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`)
      //   this.form.pageNo = val
      //   this.handlerlist()
      // },
    },
  }
</script>

<style>
  .font {
    text-decoration: underline;
  }
  .font:hover {
    cursor: pointer;
  }
</style>
