<!--
 * @Author: your name
 * @Date: 2021-01-28 20:20:15
 * @LastEditTime: 2021-03-29 14:28:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\attendance\components\baidumap.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="地图定位"
      :visible.sync="map"
      width="50%"
      center
    >
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @ready="handler"
        @click="clickEvent"
      >
        <!--        ak="FzAhPfin2UmY2ZwHiqmGAl2XUlfSxUkY"-->
        <!-- 地图控件位置 -->
        <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation> -->
        <!-- 城市列表 -->
        <!-- <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list> -->
        <!-- 定位当前位置 -->
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :show-address-bar="true"
          :auto-location="true"
          @locationSuccess="getLoctionSuccess"
        ></bm-geolocation>
        <!-- 地图容器 -->
        <bm-view
          :style="{
            width: '100%',
            height: clientHeight + 'px',
            flex: 1,
            marginBottom: '-30px',
          }"
        ></bm-view>
        <bm-control>
          <bm-auto-complete v-model="keyword" :sug-style="{ zIndex: 10 }">
            <el-input
              v-model="keyword"
              placeholder="请输入内容"
              style="width: 300px; margin-top: 10px; margin-left: 10px"
            >
              <el-button
                slot="append"
                icon="el-icon-refresh"
                @click="setSourceCenter"
              />
            </el-input>
          </bm-auto-complete>
        </bm-control>
        <bm-local-search :keyword="keyword" :auto-viewport="true" />
      </baidu-map>
      <el-form :model="form" inline>
        <el-form-item label="lng：">
          <el-input
            v-model.number="form.longitude"
            style="width: 120px"
            size="mini"
            disabled
            placeholder="经度"
          ></el-input>
        </el-form-item>
        <el-form-item label="lat：">
          <el-input
            v-model.number="form.latitude"
            style="width: 120px"
            size="mini"
            disabled
            placeholder="纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="address">
          <el-input
            v-model="form.address"
            style="width: 200px"
            size="mini"
            disabled
            placeholder="具体位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="位置名称：">
          <el-input
            v-model="form.name"
            style="width: 150px"
            size="mini"
            placeholder="请输入位置别名"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="warning" size="small" @click.native="map = !map">
          取消
        </el-button>
        <el-button type="primary" size="small" @click.native="findlocation">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    BaiduMap,
    BmNavigation,
    BmView,
    BmGeolocation,
    BmCityList,
  } from 'vue-baidu-map'

  import avatar from '@/assets/map_marker_check.png'
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        map: false,
        center: { lng: 121.833138, lat: 39.081725 },
        zoom: 12,
        keyword: '',
        form: {
          name: '',
          longitude: '',
          latitude: '',
          address: '',
          loc_sheng: '',
          loc_shi: '',
          loc_qu: '',
        },
        clientHeight:
          document.documentElement.clientHeight / 2 < 100
            ? '200'
            : document.documentElement.clientHeight / 2, // 屏幕高度

        avatar: avatar,
        url: {
          addmap: '/setAdmin/attendance/address-add',
        },
      }
    },
    watch: {
      map(val) {
        if (!val) {
          this.form = {
            name: '',
            longitude: '',
            latitude: '',
            address: '',
            loc_sheng: '',
            loc_shi: '',
            loc_qu: '',
          }
        }
      },
    },
    methods: {
      findlocation() {
        var mappp = JSON.parse(JSON.stringify(this.form))
        delete mappp.loc_sheng
        delete mappp.loc_shi
        delete mappp.loc_qu
        postAction(this.url.addmap, mappp)
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.map = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handler({ BMap, map }) {
        let _this = this
        // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r)
            _this.center = { lng: r.longitude, lat: r.latitude } // 设置center属性值
            _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude } // 自定义覆盖物
            _this.initLocation = true
          },
          { enableHighAccuracy: true }
        )

        window.map = map
      },
      //点击地图监听
      clickEvent(e) {
        var that = this
        map.clearOverlays()

        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(e.point.lng, e.point.lat),
          {
            icon: Icon_0,
          }
        )
        map.addOverlay(myMarker)
        //用所定位的经纬度查找所在地省市街道等信息
        var point = new BMap.Point(e.point.lng, e.point.lat)
        var gc = new BMap.Geocoder()
        let _this = this
        gc.getLocation(point, function (rs) {
          console.log(rs)
          console.log(rs.addressComponents.city)
          var addComp = rs.addressComponents
          // console.log(rs.address) //地址信息
          _this.form.address = rs.address
          _this.form.loc_sheng = rs.addressComponents.province
          _this.form.loc_shi = rs.addressComponents.city
          _this.form.loc_qu = rs.addressComponents.district
        })
        this.form.longitude = e.point.lng
        this.form.latitude = e.point.lat
      },
      //定位成功回调
      getLoctionSuccess(point, AddressComponent, marker) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(point.point.lng, point.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        this.form.longitude = point.point.lng
        this.form.latitude = point.point.lat
      },
    },
  }
</script>

<style></style>
