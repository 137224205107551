var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.baidulist,
            title: "考勤地址",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.baidulist = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleradd },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.tableData } },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", type: "index" },
                      }),
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              list.label == "经纬度"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          { attrs: { type: "text" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.longitude) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          { attrs: { type: "text" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.latitude) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您真的要删除这个地址吗？",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.handlerdelete(row)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("baidumap", {
                ref: "baidu",
                on: { getlist: _vm.handlergetlist },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.baidulist = !_vm.baidulist
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.baidulist = !_vm.baidulist
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }