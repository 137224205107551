<!--
 * @Author: your name
 * @Date: 2021-01-28 16:39:43
 * @LastEditTime: 2021-03-24 14:04:39
 * @LastEditors: Please set LastEditors
 * @Description: 考勤地点列表
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\attendance\components\AddAttendance.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="baidulist" title="考勤地址" center>
      <div>
        <el-row>
          <el-button type="primary" @click="handleradd">添加</el-button>
        </el-row>
        <el-row>
          <el-table stripe :data="tableData">
            <el-table-column align="center" type="index"></el-table-column>
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            >
              <template v-if="list.label == '经纬度'" #default="{ row }">
                <el-button type="text">
                  {{ row.longitude }}
                </el-button>
                <el-button type="text">
                  {{ row.latitude }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template #default="{ row }">
                <el-popconfirm
                  title="您真的要删除这个地址吗？"
                  @confirm="handlerdelete(row)"
                >
                  <el-button
                    slot="reference"
                    style="margin-left: 10px"
                    type="text"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <baidumap ref="baidu" @getlist="handlergetlist"></baidumap>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="baidulist = !baidulist">
          确 定
        </el-button>
        <el-button @click="baidulist = !baidulist">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import baidumap from './baidumap.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      baidumap,
    },
    data() {
      return {
        baidulist: false,
        tableData: [],
        colemd: [
          {
            label: '名称',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '位置',
            align: 'center',
            prop: 'address',
            width: '',
          },
          {
            label: '经纬度',
            align: 'center',
            prop: 'start_time',
            width: '',
          },
        ],
        url: {
          list: '/setAdmin/attendance/address-list',
          delete: '/setAdmin/attendance/address-delete',
        },
      }
    },
    watch: {
      baidulist(val) {
        if (!val) {
          this.$emit('getlist')
        }
      },
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handlergetlist() {
        this.handlerlist()
      },
      handleradd() {
        this.$refs.baidu.map = true
      },
      handlerdelete(row) {
        postAction(this.url.delete, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res)
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
